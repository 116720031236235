import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "@components/shared/seo";

const BlogsSEO = () => {
  const {
    sanityBlogPage: { seo: data },
  } = useStaticQuery(graphql`
    query BlogsSeoQuery {
      sanityBlogPage {
        seo {
          pageTitle
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  return <SEO seoData={data} />;
};

export default BlogsSEO;
